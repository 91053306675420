/**
 * 修改密码
 * luxinwen
 * 2020-12
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <Form ref="editForm" :model="formData" :rules="formRule" :label-width="110">
          <FormItem label="用户名称">
            <Input class="width-m" v-model="formData.userName" disabled></Input>
          </FormItem>
          <FormItem label="登录名称">
            <Input class="width-m" v-model="formData.loginName" disabled></Input>
          </FormItem>
          <FormItem label="旧密码" prop="password">
            <Input class="width-m" v-model="formData.password" maxlength="20" type="password" password></Input>
          </FormItem>
          <FormItem label="新密码" prop="newPassword">
            <Input class="width-m" v-model="formData.newPassword" maxlength="20" type="password" password></Input>
            <p class="text-tips">*密码由大写字母、小写字母和数字组成，长度为8-20位</p>
          </FormItem>
          <FormItem label="确认密码" prop="confirmPassword">
            <Input class="width-m" v-model="formData.confirmPassword" maxlength="20" type="password" password @on-enter="submit"></Input>
            <p class="text-tips">*密码由大写字母、小写字母和数字组成，长度为8-20位</p>
          </FormItem>
        </Form>
      </div>
      <sp-footer>
        <Button type="primary" :loading="btnLoading" @click="submit">保存</Button>
        <Button @click="cancel">返回</Button>
      </sp-footer>
    </div>
  </div>
</template>

<script>
  import { encrypt } from 'rsaEncrypt';
  import md5 from 'md5';

  export default {
    data() {
      return {
        btnLoading: false,      // 保存按钮状态
        formData: {
          userName: '',         // 用户名称
          loginName: '',        // 登录名称
          password: '',         // 旧密码
          newPassword: '',      // 新密码
          confirmPassword: '',  // 确认密码
          userMobile: ''
        },
        formRule: {
          password: [
            {
              required: true,
              message: '请输入旧密码',
              trigger: 'blur'
            }
          ],
          newPassword: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value.length === 0) {
                  callback(new Error('请输入新密码'));
                } else if (value.length < 8) {
                  callback(new Error('登录密码不能少于8位'));
                } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$\-_.]*$/.test(value)) {
                  callback(new Error('密码须包含大小写字母及数字'));
                } else if (value === this.formData.password) {
                  callback(new Error('新密码不能与旧密码一样'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ],
          confirmPassword: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value.length === 0) {
                  callback(new Error('请输入确认密码'));
                } else if (value.length < 8) {
                  callback(new Error('登录密码不能少于8位'));
                } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$\-_.]*$/.test(value)) {
                  callback(new Error('密码须包含大小写字母及数字'));
                } else if (value !== this.formData.newPassword) {
                  callback(new Error('确认密码与新密码不一样'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ]
        }
      };
    },
    created() {
      let userInfo = this.getUserInfo();
      this.formData.userName = userInfo.username;
      this.formData.loginName = userInfo.name;
      this.formData.userMobile = userInfo.userMobile;
    },
    methods: {
      /**
       * 获取数据
       */
      // getData() {
      //   this.$axios({
      //     url: this.$api.system.queryUsers,
      //     data: {
      //       id: this.dataId
      //     },
      //     loading: true
      //   }).then(data => {
      //     if (data.list && data.list.length === 1) {
      //       let tmpdata = data.list[0];
      //       Object.keys(this.formData).forEach(item => {
      //         this.formData[item] = tmpdata[item];
      //       });
      //     }
      //   });
      // },
      /**
       * 保存
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要保存吗？').then(() => {
              this.$axios({
                url: this.$api.system.updatePasscode,
                data: {
                  newPassword: encrypt(this.formData.newPassword),
                  password: md5(this.formData.password),
                  userMobile: this.formData.userMobile
                },
                loading: true
              }).then(data => {
                this.notice({
                  type: 'success',
                  desc: '修改成功，稍候将跳转重新登录'
                });
                setTimeout(() => {
                  this.clearUserInfo();
                  this.$router.push({
                    name: 'login',
                  });
                }, 1500);
              });
            });
          }
        });
      },
      /**
       * 返回
       */
      cancel() {
        this.$router.go(-1);
      }
    }
  };
</script>